import { useMemo } from 'react';
import { FieldMetaState } from 'react-final-form';
import { setIn } from 'final-form';
import { InferType, Schema } from 'yup';

type ShowErrorFunc = (meta: FieldMetaState<unknown>) => boolean;

const showErrorOnChange: ShowErrorFunc = ({ submitError, dirtySinceLastSubmit, error, touched, modified }) =>
    !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));

const showErrorAfterFirstSubmit: ShowErrorFunc = ({ submitFailed, error }) => {
    return !!submitFailed && !!error;
};
export const showErrorOnBlur: ShowErrorFunc = ({ submitError, dirtySinceLastSubmit, error, touched }) =>
    !!(((submitError && !dirtySinceLastSubmit) || error) && touched);

const setInError = (errors: Record<string, string>, innerError: { path: string; message: string }) => {
    return setIn(errors, innerError.path, innerError.message);
};

const emptyObj = {};

export const makeValidate = <T extends Schema>(schema: T) => {
    // eslint-disable-next-line consistent-return
    return async function validate(values: InferType<T>): Promise<void | object> {
        try {
            await schema.validate(values, { abortEarly: false });
        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            return err.inner.reduce(setInError, emptyObj);
        }
    };
};

const useFormValidation = <T extends Schema>(
    schema: T
): {
    onValidate: ReturnType<typeof makeValidate<T>>;
    showErrorOnChange: ShowErrorFunc;
    showErrorOnBlur: ShowErrorFunc;
    showErrorAfterFirstSubmit: ShowErrorFunc;
} => {
    const validate = useMemo(() => makeValidate<T>(schema), [schema]);
    return {
        onValidate: validate,
        showErrorOnChange,
        showErrorOnBlur,
        showErrorAfterFirstSubmit,
    };
};

export default useFormValidation;
