import classNames from 'classnames';

import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Text, VSpacingContainer } from '@hh.ru/magritte-ui';

import CardEdProgramPromo from 'src/components/CardEdProgram/common/CardEdProgramPromo';
import { EdProgramDto, PriceDetailsDto } from 'src/types/EdProgram';
import { NON_BREAKING_SPACE } from 'src/utils/characters';
import { getCurrencyArray } from 'src/utils/currency';

import styles from './styles.less';

const TrlKeys = {
    free: 'career-platform.components.price.free',
    amountPerMonth: 'career-platform.components.card-course-price-details.amountPerMonth',
};

interface CardEdProgramPriceProps {
    edProgram: EdProgramDto;
}

interface PriceViewDetails {
    amount: number | null;
    amountPositive: boolean;
    strikethrough: number | null;
    free: boolean;
}

const getPriceViewDetails = (priceDetails: PriceDetailsDto): PriceViewDetails => {
    if (
        [priceDetails.amount, priceDetails.amountPerMonth, priceDetails.providerPromotionAmount].filter(Boolean)
            .length === 0
    ) {
        return {
            free: true,
            amount: null,
            amountPositive: false,
            strikethrough: null,
        };
    }

    if (priceDetails.amount && priceDetails.providerPromotionAmount) {
        return {
            amount: priceDetails.providerPromotionAmount,
            amountPositive: true,
            strikethrough: priceDetails.amount,
            free: false,
        };
    }

    if (priceDetails.amount && !priceDetails.providerPromotionAmount) {
        return {
            amount: priceDetails.amount,
            amountPositive: false,
            strikethrough: null,
            free: false,
        };
    }

    if (!priceDetails.amount && priceDetails.providerPromotionAmount) {
        return {
            amount: priceDetails.providerPromotionAmount,
            amountPositive: false,
            strikethrough: null,
            free: false,
        };
    }

    return { amount: null, amountPositive: false, strikethrough: null, free: false };
};

const CardEdProgramPriceDetails: TranslatedComponent<CardEdProgramPriceProps> = ({
    trls,
    edProgram: { priceDetails },
    edProgram,
}) => {
    if (!priceDetails) {
        return null;
    }

    const { amountPerMonth, currency } = priceDetails;
    const amountDetails = getPriceViewDetails(priceDetails);

    if (amountDetails.free) {
        return <p className={styles.amount}>{trls[TrlKeys.free]}</p>;
    }

    return (
        <VSpacingContainer default={8}>
            <CardEdProgramPromo edProgram={edProgram} />
            <VSpacingContainer default={2}>
                {(amountDetails.amount || amountDetails.strikethrough) && (
                    <div className={styles.amountDetails}>
                        {amountDetails.amount && (
                            <span
                                className={classNames(
                                    styles.amount,
                                    amountDetails.amountPositive && styles.amountPositive
                                )}
                            >
                                {getCurrencyArray(amountDetails.amount, currency).join(NON_BREAKING_SPACE)}
                            </span>
                        )}
                        {amountDetails.strikethrough && (
                            <Text typography="label-4-regular" style="secondary">
                                <s>{getCurrencyArray(amountDetails.strikethrough, currency)[0]}</s>
                                &nbsp;
                                {getCurrencyArray(amountDetails.strikethrough, currency)[1]}
                            </Text>
                        )}
                    </div>
                )}
                {amountPerMonth && (
                    <Text typography="label-4-regular">
                        {[...getCurrencyArray(amountPerMonth, currency), trls[TrlKeys.amountPerMonth]].join(
                            NON_BREAKING_SPACE
                        )}
                    </Text>
                )}
            </VSpacingContainer>
        </VSpacingContainer>
    );
};

export default translation(CardEdProgramPriceDetails);
