import { MouseEvent, useRef } from 'react';
import classNames from 'classnames';

import cardCoursePromocodeButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_promocode_button_click';
import { format, TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Tag, Text, TooltipHover } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui-icon/icon';

import useSnackbar from 'src/hooks/useSnackbar';
import { EdProgramDto } from 'src/types/EdProgram';
import { NON_BREAKING_SPACE } from 'src/utils/characters';

import styles from './styles.less';

const TrlKeys = {
    snack: 'career-platform.components.card-course.promocode.snack',
    tooltip: 'career-platform.components.card-course.promocode.tooltip',
    another: 'career-platform.components.card-course-promo.promocode.another',
    discountWithHhPromo: 'career-platform.components.card-course-promo.promocode.text',
};

interface CardEdProgramPromoProps {
    edProgram: EdProgramDto;
}

const CardEdProgramPromo: TranslatedComponent<CardEdProgramPromoProps> = ({
    edProgram: { priceDetails, style: courseCardStyle },
    edProgram,
    trls,
}) => {
    const { showSnack, hideSnack } = useSnackbar();
    const activatorRef = useRef<HTMLDivElement | null>(null);

    const handleClickCopy = (e: MouseEvent<HTMLDivElement>) => {
        if (!priceDetails?.hhPromotion?.code) {
            return;
        }
        e.stopPropagation();
        // ignore text selection
        if (window.getSelection()?.toString().length) {
            return;
        }
        void navigator.clipboard.writeText(priceDetails.hhPromotion.code);
        cardCoursePromocodeButtonClick({
            courseName: edProgram.name,
            providerId: edProgram?.provider?.id || null,
            providerName: edProgram?.provider?.name || null,
            cardType: edProgram.purpose,
            professionId: edProgram.profession?.id || null,
        });
        showSnack({
            children: format(trls[TrlKeys.snack], { '{0}': priceDetails.hhPromotion.code }),
            onClose: hideSnack,
            showClose: true,
            addon: <CheckCircleFilledSize24 initial="positive" />,
            autohideTime: 3000,
        });
    };

    if (!priceDetails) {
        return null;
    }

    return (
        <div className={styles.cardCoursePromo} onClick={handleClickCopy}>
            {!!priceDetails.providerPromotion?.discount && (
                // TODO: fix ts error after magritte-ui update
                // @ts-expect-error
                <Tag size="medium" style="positive">
                    -{priceDetails.providerPromotion?.discount}%
                </Tag>
            )}
            {priceDetails.hhPromotion && (
                <div
                    ref={activatorRef}
                    className={classNames(styles.hhPromoTag, {
                        [styles.hhPromoTagSpecial]: !courseCardStyle,
                    })}
                >
                    <Text style={!courseCardStyle ? 'special' : 'secondary'} typography="label-5-regular">
                        {[
                            priceDetails.providerPromotion?.discount && trls[TrlKeys.another],
                            format(trls[TrlKeys.discountWithHhPromo], {
                                '{discount}': priceDetails.hhPromotion.discount,
                            }),
                        ]
                            .filter(Boolean)
                            .join(NON_BREAKING_SPACE)}
                    </Text>
                    &nbsp;
                    <Text style={!courseCardStyle ? 'special' : 'secondary'} typography="subtitle-4-semibold">
                        {priceDetails.hhPromotion?.code}
                    </Text>
                </div>
            )}
            <TooltipHover placement="top-center" activatorRef={activatorRef}>
                {trls[TrlKeys.tooltip]}
            </TooltipHover>
        </div>
    );
};

export default translation(CardEdProgramPromo);
