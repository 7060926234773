import cardCourseButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

import {
    EdProgramCoursePurpose,
    EdProgramTypeFollowLink,
    EdProgramEducationPurpose,
    EdProgramPurpose,
} from 'src/types/EdProgram';
import { analytics, Goal } from 'src/utils/analytics';
import openNewBlankTab from 'src/utils/openNewBlankTab';

const TrlKeys = {
    button: {
        toCourse: 'career-platform.components.button-link-external-course.button.toCourse',
        toProgram: 'career-platform.components.button-link-external-course.button.toProgram',
    },
};

interface CardEdProgramButtonLinkExternalProps {
    edProgram: EdProgramTypeFollowLink;
}

export interface PatchUtmContentConfig {
    providerIds: string[];
    appendValue: string;
}

export const cardEdProgramButtonLinkExternalHandler = (edProgram: EdProgramTypeFollowLink): void => {
    const link = edProgram.link;

    // так обходим блокировку контента со стороны adBlock,
    // abBlock большинство ссылок от партнеров считает рекламными и скрывает за display: none
    openNewBlankTab(link);
    analytics.reach(Goal.click_profession_guide_go_to_course);
    cardCourseButtonClick({
        courseName: edProgram.name,
        streamName: edProgram?.stream?.name,
        providerId: edProgram?.provider?.id || null,
        providerName: edProgram?.provider?.name || null,
        cardType: edProgram.purpose,
        professionId: edProgram.profession?.id || null,
        coursePrice: edProgram.priceDetails.providerPromotionAmount || edProgram.priceDetails.amountPerMonth || 0,
        priceDetails: JSON.stringify(edProgram.priceDetails),
        courseLink: link,
    });
};

const purposeToKeyMapping: Record<EdProgramPurpose, string> = {
    [EdProgramCoursePurpose.Skill]: TrlKeys.button.toCourse,
    [EdProgramCoursePurpose.Profession]: TrlKeys.button.toCourse,
    [EdProgramEducationPurpose.HigherEducation]: TrlKeys.button.toProgram,
    [EdProgramEducationPurpose.SpecializedSecondaryEducation]: TrlKeys.button.toProgram,
};

const CardEdProgramButtonLinkExternal: TranslatedComponent<CardEdProgramButtonLinkExternalProps> = ({
    edProgram,
    trls,
}) => {
    return (
        <Button
            style="neutral"
            mode="primary"
            size="small"
            stretched
            onClick={() => cardEdProgramButtonLinkExternalHandler(edProgram)}
        >
            {trls[purposeToKeyMapping[edProgram.purpose]]}
        </Button>
    );
};

export default translation(CardEdProgramButtonLinkExternal);
