import { FC } from 'react';

import { Link } from '@hh.ru/magritte-ui';

interface FooterNavItemProps {
    text: string;
    href: string;
    isExternal?: boolean;
    onClick?: () => void;
    Element?: 'li' | 'p' | 'div';
}

const FooterNavLink: FC<FooterNavItemProps> = ({ text, href, isExternal = false, onClick, Element = 'li' }) => (
    <Element>
        <Link
            typography="label-2-regular"
            href={href}
            style="neutral"
            {...(isExternal ? { target: '_blank', rel: 'noreferrer noopener nofollow' } : {})}
            onClick={onClick}
        >
            {text}
        </Link>
    </Element>
);

export default FooterNavLink;
