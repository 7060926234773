import { useState } from 'react';

import { useElementShown } from '@hh.ru/analytics-js';
import cardCourseElementShown from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_element_shown';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Card, Tag, useBreakpoint } from '@hh.ru/magritte-ui';

import CardEdProgramBreadcrumbsInfo from 'src/components/CardEdProgram/common/CardEdProgramBreadcrumbsInfo';
import CardEdProgramButtonLinkExternal, {
    cardEdProgramButtonLinkExternalHandler,
} from 'src/components/CardEdProgram/common/CardEdProgramButtonLinkExternal';
import CardEdProgramLogoPromoCompany from 'src/components/CardEdProgram/common/CardEdProgramLogoPromoCompany';
import CardEdProgramPriceDetails from 'src/components/CardEdProgram/common/CardEdProgramPriceDetails';
import CardCourseSubscribe from 'src/components/CardEdProgram/common/CardEdProgramSubscribe';
import {
    EdProgramDto,
    EdProgramFormat,
    isEdProgramTypeFollowLink,
    isEdProgramTypeSubscribe,
} from 'src/types/EdProgram';
import getDataQa from 'src/utils/getDataQa';
import getKebabCase from 'src/utils/getKebabCase';
import pluralize from 'src/utils/pluralize';

import styles from './styles.less';

interface CardEdProgramProps {
    edProgram: EdProgramDto;
}

const TrlKeys = {
    breadcrumbs: {
        profession: 'career-platform.components.card-course.breadcrumbs.profession',
        skill: 'career-platform.components.card-course.breadcrumbs.skill',
        format: {
            [EdProgramFormat.OFFLINE]: 'career-platform.components.card-course.breadcrumbs.format.offline',
        },
    },
    course: {
        development: 'career-platform.components.card-course.development',
    },
    date: {
        SECOND: {
            one: 'career-platform.pluralize.second.one',
            some: 'career-platform.pluralize.second.some',
            many: 'career-platform.pluralize.second.many',
        },
        MINUTE: {
            one: 'career-platform.pluralize.minute.one',
            some: 'career-platform.pluralize.minute.some',
            many: 'career-platform.pluralize.minute.many',
        },
        HOURS: {
            one: 'career-platform.pluralize.hours.one',
            some: 'career-platform.pluralize.hours.some',
            many: 'career-platform.pluralize.hours.many',
        },
        DAYS: {
            one: 'career-platform.pluralize.days.one',
            some: 'career-platform.pluralize.days.some',
            many: 'career-platform.pluralize.days.many',
        },
        WEEKS: {
            one: 'career-platform.pluralize.weeks.one',
            some: 'career-platform.pluralize.weeks.some',
            many: 'career-platform.pluralize.weeks.many',
        },
        MONTHS: {
            one: 'career-platform.pluralize.months.one',
            some: 'career-platform.pluralize.months.some',
            many: 'career-platform.pluralize.months.many',
        },
        YEARS: {
            one: 'career-platform.pluralize.years.one',
            some: 'career-platform.pluralize.years.some',
            many: 'career-platform.pluralize.years.many',
        },
    },
    button: 'career-platform.components.button-link-external-course.button',
};

enum ActiveDialog {
    SubscribeToCourse = 'SubscribeToCourse',
}

const CardEdProgram: TranslatedComponent<CardEdProgramProps> = ({ edProgram, trls }) => {
    const { isMobile } = useBreakpoint();
    const ref = useElementShown(cardCourseElementShown, {
        courseName: edProgram.name,
        streamName: edProgram?.stream?.name,
        providerId: edProgram?.provider?.id || null,
        providerName: edProgram?.provider?.name || null,
        cardType: edProgram.purpose,
        professionId: edProgram.profession?.id || null,
        priceDetails: JSON.stringify(edProgram.priceDetails),
    });

    const [activeDialog, setActiveDialog] = useState<ActiveDialog>();

    const handleClickSubscribe = () => {
        setActiveDialog(ActiveDialog.SubscribeToCourse);
    };

    const getBreadcrumbsInfo = () => {
        return [
            edProgram.format === EdProgramFormat.OFFLINE && trls[TrlKeys.breadcrumbs.format[EdProgramFormat.OFFLINE]],
            edProgram.level && [edProgram.level.from, edProgram.level.to].filter(Boolean).join('→'),
            edProgram.duration &&
                pluralize({
                    count: edProgram.duration.value,
                    one: trls[TrlKeys.date[edProgram.duration.type].one],
                    some: trls[TrlKeys.date[edProgram.duration.type].some],
                    many: trls[TrlKeys.date[edProgram.duration.type].many],
                    useCount: true,
                }),
            edProgram.stream.name,
        ].filter(Boolean) as string[];
    };

    const handleCardClick = () => {
        // ignore text selection
        if (window.getSelection()?.toString().length) {
            return;
        }
        if (isEdProgramTypeFollowLink(edProgram)) {
            cardEdProgramButtonLinkExternalHandler(edProgram);
            return;
        }
        if (isEdProgramTypeSubscribe(edProgram)) {
            handleClickSubscribe();
        }
    };

    return (
        <>
            <Card
                ref={ref}
                onClick={handleCardClick}
                actionCard
                padding={12}
                borderRadius={isMobile ? 16 : 24}
                borderBottomLeftRadius={24}
                borderBottomRightRadius={24}
                showBorder={!edProgram.style}
                stretched
                verticalStretched
                // course.style == null считается тоже цветной карточкой и игнорит showBorder
                style={edProgram.style ? edProgram.style : undefined}
                data-qa={getDataQa('course', edProgram.id, getKebabCase(edProgram.purpose))}
            >
                <div className={styles.content}>
                    <div className={styles.topContent}>
                        <div className={styles.breadcrumbs}>
                            <CardEdProgramBreadcrumbsInfo items={getBreadcrumbsInfo()} />
                        </div>
                        <h5 className={styles.title} data-qa={getDataQa('name')}>
                            {edProgram.name}
                        </h5>
                        {edProgram.provider && (
                            <div className={styles.provider}>
                                <CardEdProgramLogoPromoCompany
                                    name={edProgram.provider.name}
                                    iconSrc={edProgram.provider.icon}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.bottomContent}>
                        {isEdProgramTypeFollowLink(edProgram) && (
                            <>
                                {edProgram.priceDetails && (
                                    <div className={styles.price}>
                                        <CardEdProgramPriceDetails edProgram={edProgram} />
                                    </div>
                                )}
                                <CardEdProgramButtonLinkExternal edProgram={edProgram} />
                            </>
                        )}
                        {isEdProgramTypeSubscribe(edProgram) && (
                            <>
                                <div className={styles.tagCourseDeveloped}>
                                    {/* TODO: fix ts error after magritte-ui update */}
                                    {/* @ts-expect-error */}
                                    <Tag style="neutral">{trls[TrlKeys.course.development]}</Tag>
                                </div>
                                <CardCourseSubscribe.Button onClick={handleClickSubscribe} />
                            </>
                        )}
                    </div>
                </div>
            </Card>
            <CardCourseSubscribe.Dialog
                isVisible={activeDialog === ActiveDialog.SubscribeToCourse}
                onClose={() => setActiveDialog(undefined)}
                dialogSubtitleText={edProgram.name}
                dialogDescriptionText={edProgram.description}
                skillId={edProgram.skill?.id}
                professionId={edProgram.profession?.id}
                analyticsParams={{
                    professionId: edProgram.profession?.id,
                    courseName: edProgram?.name,
                    skillId: edProgram.skill?.id,
                    skillName: edProgram.skill?.name,
                    providerId: edProgram?.provider?.id,
                    providerName: edProgram?.provider?.name,
                }}
            />
        </>
    );
};

export default translation(CardEdProgram);
