import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface Props {
    items: string[];
}

const CardEdProgramBreadcrumbsInfo: FC<Props> = ({ items }) => (
    <Text typography="label-5-regular" color="primary">
        <ul className={styles.list}>
            {items.map((value, index) => (
                <li key={index} className={styles.item}>
                    {value}
                </li>
            ))}
        </ul>
    </Text>
);

export default CardEdProgramBreadcrumbsInfo;
