import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import getLinkWithoutTsPynRu from 'src/utils/getLinkWithoutTsPynRu';

import styles from './styles.less';

interface Props {
    name: string;
    iconSrc?: string;
}

const CardEdProgramLogoPromoCompany: FC<Props> = ({ name, iconSrc }) => (
    <div className={styles.container}>
        {iconSrc && <img className={styles.icon} src={getLinkWithoutTsPynRu(iconSrc)} alt={name} loading="lazy" />}
        <Text typography="label-3-regular" maxLines={2}>
            {name}
        </Text>
    </div>
);

export default CardEdProgramLogoPromoCompany;
